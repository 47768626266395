export function useAccountApi() {
  const API_VER = useRuntimeConfig().API_VER_1
  const API_PROMOTION = useRuntimeConfig().API_PROMOTION
  return {
    PLAN: `${API_VER}/account/plan`,
    UPDATE_PASSWORD: `${API_VER}/updatePassword`,
    UPDATE_INFO: `${API_VER}/updateInfo`,
    VERIFY_EMAIL_API: `${API_VER}/user/send-verify-email`,
    VERIFY_EMAIL_TOKEN: `${API_VER}/user/verify-email-token`,
    HISTORY_TRANSACTION: `${API_VER}/lsgd`,
    HISTORY_TRANSACTION_SUCCESS: `${API_VER}/lsgd/last-success`,
    HISTORY_BETTING: `${API_VER}/lsbAll`,
    HISTORY_TRANSACTION_DETAIL: `${API_VER}/payment/invoice`,
    WITHDRAW_INFO: `${API_VER}/payment/indexwithdraw`,
    WITHDRAW_BANK: `${API_VER}/payment/withdrawbank`,
    WITHDRAW_CARD: `${API_VER}/payment/withdrawcard`,
    WITHDRAW_CRYPTO: `${API_VER}/payment/crypto/withdraw`,
    DEPOSIT_INFO: `${API_VER}/payment/indexdeposit`,
    PROMOTION_DEPOSIT_TIME_A_DAY: `${API_VER}/account/promotion/current-date`,
    DEPOSIT_BANK: `${API_VER}/payment/depositbank`,
    GENERATE_QR_CODE_PAY: `${API_VER}/payment/codepay/qrcode/generate`,
    DEPOSIT_CODE_PAY: `${API_VER}/user/codepay`,
    DEPOSIT_NICEPAY: `${API_VER}/payment/nicepay`,
    DEPOSIT_SENPAY: `${API_VER}/payment/deposit-sendpay`,
    DEPOSIT_GOPAY: `${API_VER}/payment/deposit-provider`,
    DEPOSIT_CODE_MOMO: `${API_VER}/user/momo/code`,
    DEPOSIT_CARD: `${API_VER}/payment/depositcard`,
    DEPOSIT_VIRTUAL: `${API_VER}/payment/cryptopay`,
    CHECK_CRYPTO: `${API_VER}/payment/cryptocheck`,
    CHECK_INVOICE: (id: string) => `${API_VER}/payment/invoice/${id}`,
    CANCEL_CRYPTO: `${API_VER}/payment/cancelcrypto`,
    SMART_PAY: `${API_VER}/payment/smartpay`,
    CARD_LIST: `${API_VER}/payment/gwinfo`,
    TRANSACTION_GAME: `${API_VER}/lsb`,
    BETTING_HISTORY: `${API_VER}/lsbAll`,
    BETTING_HISTORY_DETAIL: `${API_VER}/lsbet/`,
    TRANSACTION_PSPORT: `${API_VER}/lsbAthena`,
    VERIFY_PHONE: `${API_VER}/getOtp`,
    ACCOUNT_INFO: `${API_VER}/indexaccount`,
    BONUS_DETAIL: `${API_VER}/game/bonus/show`,
    BONUS_RECEIVE: `${API_VER}/game/bonus/receive`,
    ACCOUNT_BET_TODAY: `${API_VER}/account/bet`,
    ACCOUNT_COMMISSION: `${API_VER}/account/commissionType`,
    VERIFY_COUPON: `${API_VER}/coupon`,
    COMMISSION_PROMOTION: `${API_VER}/commissions`,
    CHANGE_AVATAR: `${API_VER}/user/avatar`,
    BANK: `${API_VER}/user/banks`,
    GAME_PLAYED: `${API_VER}/statistic/game/played`,
    BANK_SCHEDULE: `${API_VER}/payment/bank/schedule`,
    UPDATE_RECEIPT: `${API_VER}/payment/binary/statement/`,
    LAST_WINNER_NUMBER: `${API_VER}/account/lastWinningNurmber`,
    WEEKLY_DEPOSIT_BONUS: `${API_PROMOTION}/bonus-deposit`,
    SUMMARY: `${API_PROMOTION}/commission/user/summary`,
    CANCEL_PROMOTION: `${API_VER}/payment/cancelpromotion`,
    CANCEL_SENPAY: `${API_VER}/payment/deposit-sendpay/cancel`,
    CANCEL_NICEPAY: `${API_VER}/payment/cancel-invoice`,
    CRYPTO_ADDRESS: `${API_VER}/payment/crypto/address`,
    CRYPTO_NETWORK: `${API_VER}/payment/crypto/get-tranfer-address?network=`,
    UPLOAD_IMAGE: `${API_VER}/transaction`,
    UPDATE_IMAGE: (id: string) => `${API_VER}/payment/binary/statement/${id}`,
    PROFIT_SUMMARY: `${API_VER}/account/summary`,
    COMMISSION: `${API_VER}/account/commission`
  }
}
